import { Container, Button, Navbar, Nav, NavItem, NavDropdown, MenuItem, NavLink,FormControl ,Form, Alert, Col,Row} from 'react-bootstrap';
import React, { useState, Component, useMemo, useEffect } from 'react';
import {Sida,Rubrik,Stycke,ContentWrapper} from "../ContentStyles";
import useBackend, {XLogOff, IsLoggedIn, Loading, SpinnerX, CallDownload,CallAPIGet} from '../../services/useBackend';
import {DateTimeToLocal, MultiselectGetOptions, MultiselectGetDefaultValues, escapedNewLineToLineBreakTag, ConcatLocalDateAndTime2ISO, SplitoutLocalDateFromISO, SplitoutLocalTimeFromISO, getOptions, metaLookUpTitle} from '../../services/misc';
import {MaterialReactTable} from 'material-react-table';
import Test from '../balkochhal/editRequestComponent';

function Report() {
    const [editRequestModalshow, setEditRequestModalshow] = useState(false);

    const [selectedRequestData, setSelectedRequestData] = useState({Contact: { Name:"N/A"}, RequestWF: [], RequestChilds : [], RequestLog : [], RequestFiles: [], UserID:0 });  
    const [contactData, setContactData] = useState({});    

    const {loading, data, Seek} =  useBackend('/api/report/resourceoverview');
    const {loading:loadingmeta, data:metadata} =  useBackend(process.env.PUBLIC_URL+'/api/meta');    

    var today = new Date(Date.now());
    //var firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const [startDate, setStartDate] = useState(today.toLocaleDateString());

    //var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth()+1, 0);
    var result = new Date(); // not instatiated with date!!! DANGER
    result.setDate(today.getDate() + 7);

    const [endDate, setEndDate] = useState(result.toLocaleDateString());

    const [reportFilter, setReportFilter] = useState(0);

    const initialState = { density: 'compact', columnVisibility: { }};    

    useEffect(() => { Seek("&UserCategoryID="+reportFilter+"&startDate="+startDate+"&endDate="+endDate) }, [reportFilter, startDate, endDate]);

        const editRequestHandler = async (RequestGuid)  =>  {
            // var isFullScreen = event.getAttribute("isFullScreen");
            
            // if (!isFullScreen) {
                let {resultVariant, resultVisible, resultText, errorsException, jsondata} = await CallAPIGet('/api/requests/' + RequestGuid);
                setSelectedRequestData(jsondata.Request)
                setContactData(jsondata.Request.Contact);
                setEditRequestModalshow(!editRequestModalshow);
            //}        
        }

    const columns = useMemo(() => [

            { size: 20, accessorKey:'Date', header: 'Date'        , enableColumnActions: false,
                Cell: ({ cell }) => SplitoutLocalDateFromISO(cell.getValue()),        
            },
            { size: 40, accessorKey: 'TET',   header: 'Total estimated time', enableColumnActions: false },
            { size: 40, accessorKey: 'Cnt',   header: 'Cnt task', enableColumnActions: false },
            
            { size: 40, accessorKey: 'ss' ,  header: 'Total numbers of resources', enableColumnActions: false },         
            { size: 40, accessorKey: 'ATR',   header: 'Avg time / resource', enableColumnActions: false },

            { size: 20, accessorKey:'Requests', header: 'Requests'        , enableColumnActions: false,
                Cell: ({ cell }) => SplitRQs(cell.getValue()),    
            },
        ]
    );    

    function SplitRQs(inData){
        return inData.map((Rq) => {
            return <><a id={Rq.Identifier} onClick={() => editRequestHandler(Rq.Guid)}>{Rq.Identifier}</a>&nbsp;&nbsp;</>;
        });      
    }  

    return(
        <ContentWrapper>
                {!loading && !loadingmeta &&
                    <Test RequestData={selectedRequestData} MetaData={metadata} show={editRequestModalshow} fn={ ()=> { setEditRequestModalshow(!editRequestModalshow);  } } setSelectedRequestData={setSelectedRequestData} contactData={selectedRequestData.Contact} />
                }
                <Sida>
                    <Rubrik>Resource overview</Rubrik>

                    <Row>
                        <Col>Startdate</Col>
                        <Col>Enddate</Col>
                        <Col>Resuource Cathegory</Col>
                    </Row>

                    <Row>
                        <Col><Form.Control type="date" name="Name"  placeholder="Startdate" value={startDate} onChange={async (e) => {await setStartDate(e.target.value); }} /></Col>
                        <Col><Form.Control type="date" name="Name_" placeholder="Enddate"   value={endDate}   onChange={async (e) => {setEndDate(e.target.value); }} /></Col>
                        <Col>
                            <Form.Select name="UserCategoryID" m onChange={async (e) => {setReportFilter(e.target.value); }} >
                                {/* <option key="0" value="0">Alla</option>                                            */}
                                {!loadingmeta && getOptions(metadata.UserCategories)}
                            </Form.Select>                    
                        </Col>
                        {/* <Col><Button variant='secondary' onClick={() => {Seek("?startdate="+startDate+"&enddate="+endDate+"&ShopId="+shopFilter)}}>Sök/Uppdatera</Button> </Col> */}
                    </Row>

                    {!loading &&                          
                    <MaterialReactTable columns={columns} data={data.reportcontent} initialState={initialState}
                        muiTablePaginationProps={{ showFirstButton: false, showLastButton: false, rowsPerPageOptions: [25, 50, 75, 100], labelRowsPerPage:"Rader"}}
                
                        muiTablePaperProps={{elevation: 0, sx: {borderRadius: '0', border: '0px dashed #e0e0e0'} }} 
                    />}                               

                    <Alert>
                    <Alert.Heading>Om resursöversikt</Alert.Heading>
                    •	Alla tider anges i minuter (en arbetsdag = 4800 minuter)<br></br>
                    •	Det är viktigt att alla Task-typer och personal tillhör rätt resursgrupp. <a href="/reports/eccd1139-2e12-48dc-9dd3-2deebc1e7783">Resursgrupper</a> <a href="/reports/200238f7-02e7-46ad-8874-17de924f4f42">Användare</a><br></br>
                    •	Rapporten kommer vara tillförlitlig först efter att tider är inmatade på arbetsuppfterna/taskarna.
                    </Alert>  

                </Sida>    
        </ContentWrapper>
      );
   }

export default Report;