import { Container, Button, Navbar, Nav, NavItem, NavDropdown, MenuItem, NavLink,FormControl ,Form, Alert, Col,Row} from 'react-bootstrap';
import React, { useState, Component, useMemo, useEffect } from 'react';
import {Sida,Rubrik,Stycke,ContentWrapper} from "../ContentStyles";
import useBackend, {XLogOff, IsLoggedIn, Loading, SpinnerX, CallDownload} from '../../services/useBackend';
import {DateTimeToLocal, MultiselectGetOptions, MultiselectGetDefaultValues, escapedNewLineToLineBreakTag, ConcatLocalDateAndTime2ISO, SplitoutLocalDateFromISO, SplitoutLocalTimeFromISO, getOptions, metaLookUpTitle} from '../../services/misc';
import {MaterialReactTable} from 'material-react-table';

function Report() {

    const {loading, data, Seek} =  useBackend('/api/report/users');
    const {loading:loadingmeta, data:metadata} =  useBackend(process.env.PUBLIC_URL+'/api/meta');    

    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 25 });
    const initialState = { density: 'compact', pagination: pagination };    

    const columns = useMemo(() => [

            { size: 40, accessorKey: 'name',   header: 'Name', enableColumnActions: false },
            { size: 40, accessorKey: 'username',   header: 'Username', enableColumnActions: false },

            { size: 20, accessorKey:'lastlogin_datetime', header: 'Last login'        , enableColumnActions: false,
                Cell: ({ cell }) => SplitoutLocalDateFromISO(cell.getValue()),        
            },
            { size: 40, accessorKey: 'UserCategory' ,  header: 'Resource group', enableColumnActions: false },         
        ]
    );    

    return(
        <ContentWrapper>
            <Container>
                <Sida>
                    <Rubrik>Users</Rubrik>

                    {!loading &&                          
                    <MaterialReactTable columns={columns} data={data.reportcontent} initialState={initialState}
                        muiTablePaginationProps={{ showFirstButton: false, showLastButton: false, rowsPerPageOptions: [25, 50, 75, 100], labelRowsPerPage:"Rader"}}
                
                        muiTablePaperProps={{elevation: 0, sx: {borderRadius: '0', border: '0px dashed #e0e0e0'} }} 
                    />}                               
                </Sida>    
            </Container>
        </ContentWrapper>
      );
   }

export default Report;