import { Container, Button, Navbar, Nav, NavItem, NavDropdown, MenuItem, NavLink,FormControl ,Form, Alert, Col,Row} from 'react-bootstrap';
import React, { useState, Component, useMemo, useEffect } from 'react';
import {Sida,Rubrik,Stycke,ContentWrapper} from "../ContentStyles";
import useBackend, {XLogOff, IsLoggedIn, Loading, SpinnerX, CallDownload} from '../../services/useBackend';
import {DateTimeToLocal, MultiselectGetOptions, MultiselectGetDefaultValues, escapedNewLineToLineBreakTag, ConcatLocalDateAndTime2ISO, SplitoutLocalDateFromISO, SplitoutLocalTimeFromISO, getOptions, metaLookUpTitle} from '../../services/misc';
import {MaterialReactTable} from 'material-react-table';

function Report() {

    const {loading, data, Seek} =  useBackend('/api/report/ResourceCategories');

    const initialState = { density: 'compact', pagination: { pageIndex: 0, pageSize: 25 } };    

    const columns = useMemo(() => [
            { size: 40, accessorKey: 'Name',   header: 'Name', enableColumnActions: false }  ,
            { size: 40, accessorKey: 'UserCategoryName',   header: 'UserCategory', enableColumnActions: false }  
        ]
    );    

    return(
        <ContentWrapper>
                <Sida>
                    <Rubrik>Resource Categories</Rubrik>

                    {!loading &&                          
                    <MaterialReactTable columns={columns} data={data.reportcontent} initialState={initialState}
                        muiTablePaginationProps={{ showFirstButton: false, showLastButton: false, rowsPerPageOptions: [25, 50, 75, 100], labelRowsPerPage:"Rader"}}
                
                        muiTablePaperProps={{elevation: 0, sx: {borderRadius: '0', border: '0px dashed #e0e0e0'} }} 
                    />}          

                                        <Alert>
                                        <Alert.Heading>Om resurskategorier</Alert.Heading>
                                        •	Skicka förändringar till fredrik@cloudsolution.se
                                        </Alert>                       
                </Sida>    
        </ContentWrapper>
      );
   }

export default Report;