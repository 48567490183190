import Container from 'react-bootstrap/Container';
import { Alert, Button, Card, Table, Modal, Accordion } from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,Stycke,StyckeCompact} from "../ContentStyles";
import useBackend, {XLogOff, IsLoggedIn,Loading, Check, CallAPI, CallAPIGet} from '../../services/useBackend';
import {useParams, useNavigate, useSearchParams} from 'react-router-dom';
import {MaterialReactTable} from 'material-react-table';
import React, { useState ,useEffect, useReducer, fetchData,useMemo} from 'react';
import {DateTimeToLocal, MultiselectGetOptions, MultiselectGetDefaultValues, escapedNewLineToLineBreakTag, ConcatLocalDateAndTime2ISO, SplitoutLocalDateFromISO, SplitoutLocalTimeFromISO, getOptions, metaLookUpTitle} from '../../services/misc';
import Test from './editRequestComponent';

function ToDo() {  
  const [editRequestModalshow, setEditRequestModalshow] = useState(false);

  const [selectedRequestData, setSelectedRequestData] = useState({Contact: { Name:"N/A"}, RequestWF: [], RequestChilds : [], RequestLog : [], RequestFiles: [], UserID:0 });  
  const [contactData, setContactData] = useState({});

  const {loading:loadingmeta, data:metadata} =  useBackend(process.env.PUBLIC_URL+'/api/meta');    
  const {loading, data, Refresh, Seek} =  useBackend('/api/MyWork');

  const [searchParams] = useSearchParams();
  var pageIndex = parseInt(searchParams.get('pageindex'),10) || 0;
  var pageSize  = parseInt(searchParams.get('pagesize'),10)  || 100;

  const [pagination, setPagination] = useState({ pageIndex: pageIndex, pageSize: pageSize });

  const editRequestHandler = async (event, rowData)  =>  {
    console.log(rowData.original);
    let {resultVariant, resultVisible, resultText, errorsException, jsondata} = await CallAPIGet('/api/requests/' + rowData.original.RequestGuid);
		setSelectedRequestData(jsondata.Request)
		setContactData(jsondata.Request.Contact);
		setEditRequestModalshow(!editRequestModalshow);
	}

  const columns = useMemo(() => [
    { size: 40, accessorKey: 'RequestIdentifier',   header: 'Id', enableColumnActions: false },
    { size: 40, accessorKey: 'ContactName',   header: 'Customer', enableColumnActions: false },
    { size: 40, accessorKey: 'RequestTitle',   header: 'Request', enableColumnActions: false },

    { size: 20, accessorKey:'RequestWFItemID', header: 'Task'           , enableColumnActions: false,     //    filterVariant: 'select', filterSelectOptions: [],
      Cell: ({ cell }) => metaLookUpTitle(metadata.RequestWFItems, cell.getValue()),        
    },  

    { size: 20, accessorKey:'Date', header: 'Date Ready'        , enableColumnActions: false,
      Cell: ({ cell }) => SplitoutLocalDateFromISO(cell.getValue()),        
    },

    { size: 20, accessorKey:'RequestsWFStateItemID', header: 'State'           , enableColumnActions: false,
        Cell: ({ cell }) => metaLookUpTitle(metadata.RequestWFStateItems, cell.getValue()),
    }, 

    { size: 20, accessorKey:'RequestPriorityItemID', header: 'Priority'           , enableColumnActions: false,
        Cell: ({ cell }) => metaLookUpTitle(metadata.RequestPriorityItems, cell.getValue()),        
    },            
  ]
);

const initialState = { density: 'compact', columnVisibility: { }, pagination : pagination };    

const state = { pagination : pagination };    

if (!loading&&!loadingmeta) {
  return (
    <ContentWrapper>
        <Test RequestData={selectedRequestData} MetaData={metadata} show={editRequestModalshow} fn={()=> {setEditRequestModalshow(!editRequestModalshow); Refresh();} } setSelectedRequestData={setSelectedRequestData} contactData={contactData} />  
            <Sida>
                  <MaterialReactTable  columns={columns} data={data.MyWork} onPaginationChange={setPagination} state={state} initialState={initialState} 
                      muiTablePaginationProps={{ showFirstButton: false, showLastButton: false, rowsPerPageOptions: [25, 50, 75, 100] ,labelRowsPerPage:"Rader"}}
                      renderTopToolbarCustomActions={() => (<Rubrik>MyWork</Rubrik>)}
                      
                          muiTablePaperProps={{elevation: 0, sx: {borderRadius: '0',border: '0px dashed #e0e0e0'} }} 
                          muiTableBodyRowProps={({ row }) => ({
                              onClick: (event) => {editRequestHandler(event, row);},sx: {cursor: 'pointer'},})}
                  />                                             
            </Sida>
    </ContentWrapper>        
  );
} else {
    return (<Loading />);        
}    
}

export default ToDo;