import Container from 'react-bootstrap/Container';
import { Alert, Button, Card, Table, Modal, Accordion, Form } from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,Stycke,StyckeCompact} from "../ContentStyles";
import useBackend, {CallAPI, Loading, SpinnerX, Check, CallAPIGet} from '../../services/useBackend';
import React, { useState ,useEffect, useReducer, fetchData,useMemo} from 'react';
import {useParams, useNavigate, useSearchParams} from 'react-router-dom';
import {MaterialReactTable} from 'material-react-table';
import {DateTimeToLocal, MultiselectGetOptions, MultiselectGetDefaultValues, escapedNewLineToLineBreakTag, ConcatLocalDateAndTime2ISO, SplitoutLocalDateFromISO, SplitoutLocalTimeFromISO, getOptions, metaLookUpTitle} from '../../services/misc';
import CreateRequest from './createRequestComponent';

function Contacts() {
  const [saving,        setSaving]        = useState(false);
  const [resultVariant, setResultVariant] = useState('warning');
  const [resultText,    setResultText]    = useState('N/A');
  const [resultVisible, setResultVisible] = useState(false);  

  const {loading, data, Refresh} =  useBackend(process.env.PUBLIC_URL+'/api/contacts');
  const {loading:loadingmeta, data:metadata} =  useBackend(process.env.PUBLIC_URL+'/api/meta');

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  var pageIndex = parseInt(searchParams.get('pageindex'),10) || 0;
  var pageSize  = parseInt(searchParams.get('pagesize'),10)  || 25;

  const [pagination, setPagination] = useState({ pageIndex: pageIndex, pageSize: pageSize });
  const [editContactData, setEditContactData] = useState({});
  const [editContactRequestListData, setEditContactRequestListData] = useState([]);
  const [contactIsCurrent, setContactIsCurrent] = useState(true);

  const [editContactModalshow, setEditContactModalshow] = useState(false);
  const [requestHistoryModalshow, setRequestHistoryModalshow] = useState(false);
  const [newRequestModalshow, setNewRequestModalshow] = useState(false);
  
  // const initModal = () => { return setEditProductModalshow(!editProductModalshow) } 

  useEffect(() => { navigate('?pageindex='+pagination.pageIndex+'&pagesize='+pagination.pageSize) }, [pagination]);

  var typingTimeout = null;

	function handleChange(event){
		if (contactIsCurrent==true) {
			clearTimeout(typingTimeout);
			typingTimeout = setTimeout(handleSave, 475);
		}
	}

  async function handleSave(){
    setSaving(true);
    setResultText("");
    setResultVisible(false);

    console.log('handleSave');
    const ContactForm = document.getElementById('ContactForm');
    const formData = new FormData(ContactForm);
    const formDataObj = Object.fromEntries(formData.entries());

    if (contactIsCurrent==false) {
      let {resultVariant, resultVisible, resultText, jsondata} = await CallAPI('/api/contacts', 'POST', JSON.stringify(formDataObj));
      console.log(resultText);
        setEditContactData(jsondata.contact);
        setContactIsCurrent(true);
        setResultVariant(resultVariant);   
        setResultText(resultText);
        setResultVisible(resultVisible);
    } else {
       let {resultVariant, resultVisible, resultText, errorsException} = await CallAPI('/api/contacts/'+editContactData.Guid,"PUT",JSON.stringify(formDataObj));
       if (resultVariant!="success") {
         setResultVariant(resultVariant);   
         setResultText(resultText);
         setResultVisible(resultVisible);
       } 
    }
    Refresh();
    setSaving(false);
  }

  // Ny contact
  const newContactHandler = (event)  =>  {
    setContactIsCurrent(false);
    setEditContactModalshow(true);
    setEditContactData({});
  }

  // Editera Contact
  const editContactHandler = (event, rowData)  =>  {
    setContactIsCurrent(true);
    setEditContactModalshow(true);
    setEditContactData(rowData.original);
  }

    // Spara ny Contact
    const SaveNewContactHandler = (event)  =>  {
      handleSave();

    }

  // Ny request
  const AddRequestHandler = (event, rowData)  =>  {
    setEditContactModalshow(false);
    setNewRequestModalshow(true);
  }

  const viewRequestHistoryHandler = async (event)  =>  {
    let {jsondata} = await CallAPIGet('/api/Contacts/'+editContactData.Guid, 'GET');
    console.log(jsondata.Requests);
    setEditContactRequestListData(jsondata.Requests);
    setRequestHistoryModalshow(true);
  }



  const columns = useMemo(() => [
    { size: 40, accessorKey: 'Name',   header: 'Name', enableColumnActions: false },
    { size: 40, accessorKey: 'phonenumber',  header: 'Phone' },
    { size: 40, accessorKey: 'city',  header: 'City' },
    { size: 20, accessorKey: 'misc01', header: 'Anm', enableOrdering: false, enablePinning: false, enableColumnActions: true},                           
  ]
);

const initialState = { density: 'compact', columnVisibility: { 'Manuell registrering': false, 'Visa på prislista':false, 'Price':false }, pagination : pagination };    

const state = { pagination : pagination };    

if (!loading&&!loadingmeta) {
  return (    
    <ContentWrapper>

      <Modal show={requestHistoryModalshow} onHide={()=>setRequestHistoryModalshow(false)} size='xl'>
				<Modal.Header closeButton>
					<Modal.Title>Requests</Modal.Title>
				</Modal.Header>			
				<Modal.Body>

        <Table responsive="sm">
                                      <thead>
                                          <tr><th>Id</th><th>date</th><th>State</th><th>Title</th></tr>
        {editContactRequestListData.map((state) => { 
                                          return <tr>
                                          <td>{state.Identifier}</td>
                                          <td>{SplitoutLocalDateFromISO(state.Created)}</td>
                                          <td>{metaLookUpTitle(metadata.RequestStateItems, state.RequestStateItemID)}</td>
                                          <td>{state.Title}</td>
      </tr> })}
      </thead>
      </Table>
        
					
				</Modal.Body>
			</Modal>

      <CreateRequest MetaData={metadata} DefaultValues={{}} show={newRequestModalshow} CreateRequestToggle={ ()=> { setNewRequestModalshow(!newRequestModalshow); Refresh(); } } contactData={editContactData} />

			<Modal show={editContactModalshow} onHide={()=>setEditContactModalshow(false)} size='xl'>
				<Modal.Header closeButton>
					<Modal.Title>Contact details</Modal.Title>
				</Modal.Header>			
				<Modal.Body>
					<Form id='ContactForm' name='ContactForm'>							          													
            <Form.Group className="mb-3" >
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" name="Name" id="Name" placeholder="Name" defaultValue={editContactData.Name}  onChange={handleChange}/>
            </Form.Group>

            <Form.Group className="mb-3" >
              <Form.Label>SubUnit</Form.Label>
              <Form.Control type="text" name="SubUnit" id="SubUnit" placeholder="SubUnit" defaultValue={editContactData.SubUnit}  onChange={handleChange}/>
            </Form.Group>

            <Form.Group className="mb-3" >
              <Form.Label>Orgnr</Form.Label>
              <Form.Control type="text" name="orgnr" id="orgnr" placeholder="orgnr" defaultValue={editContactData.orgnr}  onChange={handleChange}/>
            </Form.Group>

            <Form.Group className="mb-3" >
              <Form.Label>eMail</Form.Label>
              <Form.Control type="text" name="eMail" id="eMail" placeholder="eMail" defaultValue={editContactData.eMail}  onChange={handleChange}/>
            </Form.Group>

            <Form.Group className="mb-3" >
              <Form.Label>Phonenumber</Form.Label>
              <Form.Control type="text" name="phonenumber" id="phonenumber" placeholder="phonenumber" defaultValue={editContactData.phonenumber}  onChange={handleChange}/>
            </Form.Group>

            <Form.Group className="mb-3" >
              <Form.Label>Street01</Form.Label>
              <Form.Control type="text" name="street01" id="street01" placeholder="street01" defaultValue={editContactData.street01} onChange={handleChange}/>
            </Form.Group>

            <Form.Group className="mb-3" >
              <Form.Label>Street02</Form.Label>
              <Form.Control type="text" name="street02" id="street02" placeholder="street02" defaultValue={editContactData.street02} onChange={handleChange}/>
            </Form.Group>

            <Form.Group className="mb-3" >
              <Form.Label>Zip</Form.Label>
              <Form.Control type="text" name="zip" id="zip" placeholder="zip" defaultValue={editContactData.zip} onChange={handleChange}/>
            </Form.Group>

            <Form.Group className="mb-3" >
              <Form.Label>City</Form.Label>
              <Form.Control type="text" name="city" id="city" placeholder="city" defaultValue={editContactData.city} onChange={handleChange}/>
            </Form.Group>      

            <Form.Group className="mb-3"  hidden={!saving}>
                <SpinnerX  />
            </Form.Group>                                      
            
            <Form.Group className="mb-3"  hidden={!resultVisible}>
                <Alert show={resultVisible} variant={resultVariant}  className="FormSpace">{resultText}</Alert> 
            </Form.Group>                                                                                                                       

						<Form.Group className="mb-3" >

              {contactIsCurrent &&
              <>
							  <Button className="QmeButton" variant="primary" type="button" onClick={() => {AddRequestHandler()} } >Create Request</Button>&nbsp;              
                <Button className="QmeButton" variant="primary" type="button" onClick={() => {viewRequestHistoryHandler()}}>View all Requests</Button>
              </>}

              {!contactIsCurrent &&
                <Button className="QmeButton" variant="primary" type="button" onClick={() => {SaveNewContactHandler()} } >Save</Button>           
              }              
              
						</Form.Group>						
					</Form>	
				</Modal.Body>
			</Modal>

      <Sida>
          <MaterialReactTable  columns={columns} data={data.Contacts}  onPaginationChange={setPagination} state={state} initialState={initialState}
              muiTablePaginationProps={{ showFirstButton: false, showLastButton: false ,labelRowsPerPage:"Rader"}}
              renderTopToolbarCustomActions={() => (<Rubrik>Contacts</Rubrik>)}
              
                  muiTablePaperProps={{elevation: 0, sx: {borderRadius: '0',border: '0px dashed #e0e0e0'} }} 
                  muiTableBodyRowProps={({ row }) => ({
                      onClick: (event) => {editContactHandler(event, row);},sx: {cursor: 'pointer'},})}
          />                         
          <Button className="QmeButton" variant="primary" type="submit" onClick={newContactHandler}>New Contact</Button>                           
                                   
      </Sida>
    </ContentWrapper>        
  );
} else {
    return (<Loading />);        
}  
}

export default Contacts;