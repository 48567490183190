import Container from 'react-bootstrap/Container';
import { Alert, Button, Card, Table, Modal, Accordion, Form } from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,Stycke,StyckeCompact} from "../ContentStyles";
import useBackend, {CallAPI, Loading, SpinnerX, Check} from '../../services/useBackend';
import React, { useState ,useEffect, useReducer, fetchData,useMemo} from 'react';
import {useParams, useNavigate, useSearchParams} from 'react-router-dom';
import {MaterialReactTable} from 'material-react-table';
import {DateTimeToLocal, MultiselectGetOptions, MultiselectGetDefaultValues, escapedNewLineToLineBreakTag, ConcatLocalDateAndTime2ISO, SplitoutLocalDateFromISO, SplitoutLocalTimeFromISO, getOptions, metaLookUpTitle} from '../../services/misc';
import { FaEdit, FaEraser, FaSortDown, FaSortUp, FaDownload } from 'react-icons/fa';

export default function CreateRequest(props) {
    const [saving,        setSaving]        = useState(false);    
    const [resultVariant, setResultVariant] = useState('warning');
    const [resultText,    setResultText]    = useState('N/A');
    const [resultVisible, setResultVisible] = useState(false);  

    const [enableSave,        setEnableSave]        = useState(true);    

    const CreateRequestHandler = async (event)  =>  {
        const NewRequestForm = document.getElementById('NewRequestForm');
        const formData = new FormData(NewRequestForm);
        const formDataObj = Object.fromEntries(formData.entries());
        setSaving(true);
        formDataObj.ContactID = props.contactData.Id;
        if (props.DefaultValues.Id!=null) {
            formDataObj.CopyWorkflowFromRequestId = props.DefaultValues.Id;
        }
        formDataObj.expected_delivery = ConcatLocalDateAndTime2ISO(document.getElementById('expected_delivery').value);

        let {resultVariant, resultVisible, resultText, jsondata} = await CallAPI('/api/Requests', 'POST', JSON.stringify(formDataObj));

        var delayInMilliseconds = 1000; //1 second
        setTimeout(function() {
        //your code to be executed after 1 second
        }, delayInMilliseconds);        
        
        setResultVisible(resultVisible);
        setResultText(resultText);
        setSaving(false);

        if(resultVariant=="success")
        {
            props.CreateRequestToggle();
            setEnableSave(true);   
        } 
        else
        {
            setEnableSave(true);         
        }        
    }

    return (
        <>
        {/* </><Modal show={addWFStepModalshow} onHide={addWFStepInitModal} size='xl'  style={{zIndex: 1510, backgroundColor: '#aaa'}}> */}
        <Modal show={props.show} onHide={props.CreateRequestToggle} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>Create Request</Modal.Title>
                </Modal.Header>			
                <Modal.Body>
                    <Form id='NewRequestForm' name='NewRequestForm'>					        

                        <Form.Group className="mb-3" >
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" name="Title" id="Title" placeholder="Title" defaultValue={props.DefaultValues.Title} />
                        </Form.Group>

                        <Form.Group className="mb-3" >
                            <Form.Label>Beskrivning</Form.Label>								
                            <Form.Control as="textarea" rows="3" type="text" name="Description" id="Description" placeholder="Produktbeskrivning" defaultValue={props.DefaultValues.Description} />
                        </Form.Group>  

                        <Form.Group className="mb-3" >
                            <Form.Label>Customer ref:</Form.Label>								
                            <Form.Control type="text" name="Custref" id="Custref" placeholder="Customer ref"  defaultValue={props.DefaultValues.Custref} />
                        </Form.Group>

                        <Form.Group className="mb-3" >
                            <Form.Label>Expected delivery:</Form.Label>								
                            <Form.Control type="date" name="expected_delivery" id="expected_delivery" placeholder="Expected delivery" />
                        </Form.Group>                        

                        <Form.Group className="mb-3" >
                            <Form.Label>Priority</Form.Label>
                            <Form.Select name="RequestPriorityItemId" id="RequestPriorityItemId" defaultValue={3}>
                            {getOptions(props.MetaData.RequestPriorityItems)}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" >
                            <Form.Label>State</Form.Label>
                            <Form.Select name="RequestStateItemID" id="RequestStateItemID"  defaultValue={1}>
                            {getOptions(props.MetaData.RequestStateItems)}
                            </Form.Select>
                        </Form.Group>
                        
                        <Form.Group className="mb-3" >
                            <Form.Label>Assignee</Form.Label>
                            <Form.Select name="UserID" id="UserID" defaultValue={props.MetaData.User.Id}>
                            <option value={0}>--</option>
                            {getOptions(props.MetaData.Users)}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" >
                            {props.DefaultValues.Id!=null && <>Workflow kommer att kopieras från Request: {props.DefaultValues.Identifier} </> }                            
                        </Form.Group>         

                        <Form.Group className="mb-3"  hidden={!saving}>
                            <SpinnerX  />
                        </Form.Group>                                      
                        
                        <Form.Group className="mb-3"  hidden={!resultVisible}>
                            <Alert show={resultVisible} variant={resultVariant}  className="FormSpace">{resultText}</Alert> 
                        </Form.Group>                                        

                        

                        <Form.Group className="mb-3" >
                            <Button className="QmeButton" variant="primary" type="button" onClick={() => {CreateRequestHandler()}} disabled={!enableSave} >Create Request</Button>
                        </Form.Group>						
                    </Form>	
                </Modal.Body>
            </Modal>
        </>
      );      
};