import Container from 'react-bootstrap/Container';
import { Alert, Button, Card, Table, Modal, Accordion } from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,Stycke,StyckeCompact} from "../ContentStyles";
import useBackend, {XLogOff, IsLoggedIn, Loading, CallAPIGet} from '../../services/useBackend';
import {useParams, useNavigate, useSearchParams} from 'react-router-dom';
import React, { useState ,useEffect, useReducer, fetchData, useMemo, useRef} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {DateTimeToLocal, MultiselectGetOptions, MultiselectGetDefaultValues, escapedNewLineToLineBreakTag, ConcatLocalDateAndTime2ISO, SplitoutLocalDateFromISO, SplitoutLocalTimeFromISO, getOptions, metaLookUpTitle} from '../../services/misc';
import Test from './editRequestComponent';
import Box from '@mui/material/Box';

function Workflow() {
    const [editRequestModalshow, setEditRequestModalshow] = useState(false);

    const [selectedRequestData, setSelectedRequestData] = useState({Contact: { Name:"N/A"}, RequestWF: [], RequestChilds : [], RequestLog : [], RequestFiles: [], UserID:0 });  
    const [contactData, setContactData] = useState({});

    const {loading:loadingmeta, data:metadata} =  useBackend(process.env.PUBLIC_URL+'/api/meta');    
    const {loading, data, Refresh, Seek} =  useBackend(process.env.PUBLIC_URL+'/api/Workflow/v2');

    const [searchParams] = useSearchParams();
    var pageIndex = parseInt(searchParams.get('pageindex'),10) || 0;
    var pageSize  = parseInt(searchParams.get('pagesize'),10)  || 100;

    const [pagination, setPagination] = useState({ pageIndex: pageIndex, pageSize: pageSize });    

    const navigate = useNavigate();

    useEffect(() => { navigate('?pageindex='+pagination.pageIndex+'&pagesize='+pagination.pageSize) }, [pagination]);

    const editRequestHandler = async (event, rowData)  =>  {
        // var isFullScreen = event.getAttribute("isFullScreen");
        
        // if (!isFullScreen) {
            let {resultVariant, resultVisible, resultText, errorsException, jsondata} = await CallAPIGet('/api/requests/' + rowData.original.RequestGuid);
            setSelectedRequestData(jsondata.Request)
            setContactData(jsondata.Request.Contact);
            setEditRequestModalshow(!editRequestModalshow);
        //}        
    }

    var filterSelectOptions = data.filterSelectOptions;

    const longText = "Det här är en lång text i en liten textbox!!";

    const columns = useMemo(() => [
        { size: 40, accessorKey: 'IndentifierTx',   header: 'Id', enableColumnActions: false, grow: false, size:65 },

        { size: 20, accessorKey:'Date', header: 'Date'        , enableColumnActions: false,  grow: false, size:100,
            Cell: ({ cell }) => SplitoutLocalDateFromISO(cell.getValue()),        
        },

        { size: 40, accessorKey: 'TaskTx',  header: 'Task', filterVariant: 'select', filterSelectOptions: filterSelectOptions },

        { size: 20, accessorKey: 'RequestPriorityItemID', header: 'Priority', enableColumnActions: false,
            Cell: ({ cell }) => ( <span>{metaLookUpTitle(metadata.RequestPriorityItems, cell.getValue())}</span> ),
        }, 

        { size: 20, accessorKey:'RequestsWFStateItemID', header: 'State'           , enableColumnActions: false,  grow: false, size:100,
            Cell: ({ cell }) => metaLookUpTitle(metadata.RequestWFStateItems, cell.getValue()),
        }, 
        
        { accessorKey: 'misc01', header: 'misc01', size: 20, 
            Cell: ({ cell }) => { return <div style={{width:'100px', whiteSpace: 'normal', wordBreak: 'break-word', fontSize:'14px' }}> {cell.getValue()} </div>; },
        },

        { accessorKey: 'misc02', header: 'misc02', size: 20, 
            Cell: ({ cell }) => { return <div style={{width:'100px', whiteSpace: 'normal', wordBreak: 'break-word' }}> {cell.getValue()} </div>; },
        },

        { accessorKey: 'misc03', header: 'misc03', size: 20, 
            Cell: ({ cell }) => { return <div style={{width:'100px', whiteSpace: 'normal', wordBreak: 'break-word' }}> {cell.getValue()} </div>; },
        },

        { accessorKey: 'misc04', header: 'misc04', size: 20, 
            Cell: ({ cell }) => { return <div style={{width:'100px', whiteSpace: 'normal', wordBreak: 'break-word' }}> {cell.getValue()} </div>; },
        },

        { accessorKey: 'misc05', header: 'misc05', size: 20, 
            Cell: ({ cell }) => { return <div style={{width:'100px', whiteSpace: 'normal', wordBreak: 'break-word' }}> {cell.getValue()} </div>; },
        },

        { size: 20, accessorKey:'assignee_id', header: 'Assignee'           , enableColumnActions: false,  grow: false, size:120,
            Cell: ({ cell }) => metaLookUpTitle(metadata.Users, cell.getValue()),
        }, 
        
        { accessorKey: 'ContactID', header: 'Customer',
            Cell: ({ cell }) => { return <div style={{width:'200px', whiteSpace: 'normal', wordBreak: 'break-word' }}> {metaLookUpTitle(metadata.Contacts, cell.getValue())} </div>; },
        },

        { accessorKey: 'RequestTitleTx', header: 'Title',
            Cell: ({ cell }) => { return <div style={{width:'200px', whiteSpace: 'normal', wordBreak: 'break-word' }}> {cell.getValue()} </div>; },
        },
        ]
    );  

  const initialState = { density: 'compact', columnVisibility: { 'Cust ref.':false }, pagination : pagination };    

  const state = { pagination : pagination };      

  if (!loading&&!loadingmeta) {
  return (
        <ContentWrapper>
        <Test RequestData={selectedRequestData} MetaData={metadata} show={editRequestModalshow} fn={()=> {setEditRequestModalshow(!editRequestModalshow); Refresh();} } setSelectedRequestData={setSelectedRequestData} contactData={contactData}/>  
        <Sida>
                  <MaterialReactTable id="Testx" Id="Testx"  columns={columns} data={data.Workflow}  onPaginationChange={setPagination} state={state} initialState={initialState} layoutMode_='grid'
                  style={{overlay: {zIndex: 100}}}
                  muiTablePaginationProps={{ showFirstButton: false, showLastButton: false, rowsPerPageOptions: [25, 50, 75, 100] ,labelRowsPerPage:"Rader"}}
                  renderTopToolbarCustomActions={() => (<Rubrik>Workflow</Rubrik>)}
                  
                      muiTablePaperProps={{elevation: 0, sx: {borderRadius: '0',border: '0px dashed #e0e0e0'} }} 
                      muiTableBodyRowProps={({ row }) => ({
                          onClick: (event) => {editRequestHandler(event, row);},sx: {cursor: 'pointer'},})}
                      />                                             
            </Sida>
    </ContentWrapper>        
  );
} else {
    return (<Loading />);        
}    
}

export default Workflow;