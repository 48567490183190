import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ContentDiv } from "./pages/ContentStyles";

import Menu                    from "./pages/Menu";
import Footer                  from './pages/Footer';

import Home                    from "./pages/Home";

import CookieConsent           from "./pages/CookieConcent"

import Login                   from "./pages/common/Login";
import MyPage                  from "./pages/common/MyPage";
import NoPage                  from "./pages/common/NoPage";
import Contact                 from "./pages/common/Contact";
import ChangePassword          from "./pages/common/ChangePassword";
import RequestNewPassword      from "./pages/common/RequestNewPassword";
import RequestNewPasswordSet   from "./pages/common/RequestNewPasswordSet";

import Example                 from "./pages/common/Example";

import MyWork                  from "./pages/balkochhal/mywork";
import Contacts                from "./pages/balkochhal/contacts";
import Requests                from "./pages/balkochhal/requests";
import Workflow                from "./pages/balkochhal/workflow";
import Reports                 from "./pages/balkochhal/reports";
import Debug                   from "./pages/balkochhal/debug";
import ReportsResourceOverview from "./pages/reports/ReportsResourceOverview"
import ReportUsers             from "./pages/reports/ReportUsers"
import ReportResourceCategories             from "./pages/reports/ReportResourceCategories"
import ReportResourceDiviations             from "./pages/reports/ReportResourceDiviations"


export default function App() {
  return (
    <>          
        <BrowserRouter>
        <ContentDiv>
          <Routes>
            <Route index                                element={<Home />} />

            <Route path="Password/Request/new"          element={<RequestNewPassword />} />
            <Route path="Password/Request/set/:GUID"    element={<RequestNewPasswordSet />} />
            <Route path="Password/change"               element={<ChangePassword />} />
            
            <Route path="contact"                       element={<Contact />} />           
            <Route path="login"                         element={<Login />} />              
            <Route path="MyPage"                        element={<MyPage />} />   

            <Route path="Example"                       element={<Example />} />       

            <Route path="MyWork"                        element={<MyWork />} />
            <Route path="Contacts"                      element={<Contacts />} />       
            <Route path="Requests"                      element={<Requests />} />       
            <Route path="Workflow"                      element={<Workflow />} />       
            <Route path="Reports"                       element={<Reports />} />             
            <Route path="Debug"                         element={<Debug />} />         

            <Route path="Reports/200238f7-02e7-46ad-8874-17de924f4f42"  element={<ReportUsers />} />       
            <Route path="Reports/eccd1139-2e12-48dc-9dd3-2deebc1e7783"  element={<ReportResourceCategories />} />       
            <Route path="Reports/40704f03-d930-4a42-8edb-02b40b4b6871"  element={<ReportsResourceOverview />} />       
            <Route path="Reports/32b7109a-9563-450e-afc6-06c98ec84b44"  element={<ReportResourceDiviations />} />       
            <Route path="Reports/8c6aa7a1-f4bf-4871-952d-5c91a420452c"  element={<NoPage />} />               
            
            <Route path="*"                             element={<NoPage />} />
          </Routes>
          </ContentDiv>
        </BrowserRouter>

      <Footer />
      <CookieConsent />
    </>
  );
}

//ReactDOM.render(<App />, document.getElementById("root"));
const root = ReactDOM.createRoot(document.getElementById("main") );
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

const top = ReactDOM.createRoot(document.getElementById("top") );
top.render(
  <React.StrictMode>
    <Menu />
  </React.StrictMode>
);