import Container from 'react-bootstrap/Container';
import {Sida,Rubrik,ContentWrapper,Stycke,StyckeCompact} from "../ContentStyles";
import useBackend, {CallAPI, Loading, SpinnerX, Check} from '../../services/useBackend';
import React, { useState ,useEffect, useReducer, fetchData,useMemo} from 'react';
import {useParams, useNavigate, useSearchParams} from 'react-router-dom';
import {MaterialReactTable} from 'material-react-table';
import {DateTimeToLocal, MultiselectGetOptions, MultiselectGetDefaultValues, escapedNewLineToLineBreakTag, ConcatLocalDateAndTime2ISO, SplitoutLocalDateFromISO, SplitoutLocalTimeFromISO, getOptions, metaLookUpTitle} from '../../services/misc';
import Test from './editRequestComponent';

function Requests() {
	console.log("Requests");
	const [editRequestModalshow, setEditRequestModalshow] = useState(false);

	const [selectedRequestData, setSelectedRequestData] = useState({Contact: { Name:"N/A"}, RequestWF: [], RequestChilds : [], RequestLog : [], RequestFiles: [], UserID:0 });  
	const [contactData, setContactData] = useState({});

	// const [searchParams] = useSearchParams();
	// var pageIndex = parseInt(searchParams.get('pageindex'),10) || 0;
	// var pageSize  = parseInt(searchParams.get('pagesize'),10)  || 100;
	var pageSize = 100;
	var pageIndex = 0;


	//var contactid  = parseInt(searchParams.get('contactid'),10)  || 0;

	const {loading, data, Refresh, Seek} =  useBackend(process.env.PUBLIC_URL+'/api/requests', pageSize, pageIndex);
	const {loading:loadingmeta, data:metadata} =  useBackend(process.env.PUBLIC_URL+'/api/meta');

	const navigate = useNavigate();

	const editRequestHandler = (event, rowData)  =>  {
		setSelectedRequestData(rowData.original)
		setEditRequestModalshow(!editRequestModalshow);
	}

	const [pagination, setPagination] = useState({ pageIndex: pageIndex, pageSize: pageSize });

	const initialState = { density: 'compact', pagination : pagination };   

	useEffect(() => {
	  if (!loading) {
		//navigate('?pageindex='+pagination.pageIndex+'&pagesize='+pagination.pageSize) 
		Refresh(pagination.pageIndex, pagination.pageSize);
	  }
	}, [pagination]);
  

  	const columns = useMemo(() => [
		{ size: 40, accessorKey: 'Identifier',   header: 'ID' },
		{ size: 20, header: 'Created', enableColumnActions: false,
		Cell: ({ row }) => ( <span>{SplitoutLocalDateFromISO(row.original.Created)}</span> ),
		}, 
		{ size: 20, header: 'Completed', enableColumnActions: false,
		Cell: ({ row }) => ( <span>{SplitoutLocalDateFromISO(row.original.expected_delivery)}</span> ),
		}, 
		{ size: 40, accessorKey: 'Title',  header: 'Title' },
		{ size: 20, header: 'Priority', enableColumnActions: false,
		Cell: ({ row }) => ( <span>{metaLookUpTitle(metadata.RequestPriorityItems, row.original.RequestPriorityItemID)}</span> ),
		}, 
		{ size: 20, accessorKey: 'Contact.Name', header: 'Customer', enableOrdering: false, enablePinning: false, enableColumnActions: true},          
		{ size: 20, header: 'State', enableColumnActions: false,
		Cell: ({ row }) => ( <span>{metaLookUpTitle(metadata.RequestStateItems, row.original.RequestStateItemID)}</span> ),
		}, 
		{ size: 20, header: 'Assignee', 
		Cell: ({ row }) => ( <span>{metaLookUpTitle(metadata.Users, row.original.UserID)}</span> ),
		}        
  	]);  

 
	var filterDebouncer = null;

	const setGlobalFilter = (search)  =>  {
		if (!loading) {
		console.log("setGlobalFilter");
		clearTimeout(filterDebouncer);
		if (search != null && search.length > 3) filterDebouncer = setTimeout(() => handleSaveLoaddata(search), 1000);
		if (search == undefined) filterDebouncer = setTimeout(() => handleSaveLoaddata(""), 1000); // Krysset
		if (search == "") filterDebouncer = setTimeout(() => handleSaveLoaddata(""), 1000); // Suddat ut
		}
	}

	const handleSaveLoaddata = (search)  =>  {
		console.log('search: '+ search);		
		Seek(search);
	}

if (!loading&&!loadingmeta) {
  return (
        <ContentWrapper>

		<Test RequestData={selectedRequestData} MetaData={metadata} show={editRequestModalshow} fn={ ()=> { setEditRequestModalshow(!editRequestModalshow); Refresh(pagination.pageIndex, pagination.pageSize); } } setSelectedRequestData={setSelectedRequestData} contactData={selectedRequestData.Contact} />

		<Sida>
				<MaterialReactTable  
					enableSorting={false}
					enableColumnActions= {false}
					columns={columns} 
					data={data.Requests}
					manualPagination= {true}
					manualFiltering = {true}
					onGlobalFilterChange={setGlobalFilter}
					enableColumnFilters = {false}
					enableFilterMatchHighlighting = {false}
					paginationDisplayMode='pages'
					rowCount= {data.totalNumOfRows} 
					onPaginationChange={setPagination} 
					loading={loading}
					loadingText={'...'}
					state={{ pagination }} 
					enableFilters = {true}					
					muiTablePaperProps={{elevation: 0, sx: {borderRadius: '0',border: '0px dashed #e0e0e0'} }} 
					muiTablePaginationProps={{ showFirstButton: false, showLastButton: false, rowsPerPageOptions: [25, 50, 75, 100] ,labelRowsPerPage:"Rader"}}  

					initialState={initialState}                    
					renderTopToolbarCustomActions={() => (<Rubrik>Requests</Rubrik>)} 
					muiTableBodyRowProps={({ row }) => ({
						onClick: (event) => {editRequestHandler(event, row);},sx: {cursor: 'pointer'},}
					)}					
				/>                                             
		</Sida>
    </ContentWrapper>        
  );
} else {
    return (<Loading />);        
}  
}

export default Requests;