import Container from 'react-bootstrap/Container';
import { Alert, Button, Card, Table, Modal, Accordion } from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,Stycke,StyckeCompact} from "../ContentStyles";


function Reports() {
  return (

        <ContentWrapper>
            <Sida>

                <Accordion defaultActiveKey="0" >
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Rapporter</Accordion.Header>
                        <Accordion.Body>
                            {/* <a href="/reports/200238f7-02e7-46ad-8874-17de924f4f42">Användare</a><br></br>         */}
                            {/* <a href="/reports/eccd1139-2e12-48dc-9dd3-2deebc1e7783">Resursgrupper</a><br></br> */}
                            <a href="/reports/40704f03-d930-4a42-8edb-02b40b4b6871">Resursöversikt</a><br></br>
                            <a href="/reports/32b7109a-9563-450e-afc6-06c98ec84b44">Resursavvikelser</a><br></br>
                            {/* <a href="/reports/8c6aa7a1-f4bf-4871-952d-5c91a420452c">Vakant</a><br></br> */}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>               

            </Sida>
    </ContentWrapper>        
  );
}

export default Reports;