import Container from 'react-bootstrap/Container';
import { Alert, Button, Card, Table, Modal, Accordion } from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,Stycke,StyckeCompact} from "../ContentStyles";
import useBackend, {XLogOff, IsLoggedIn} from '../../services/useBackend';
import React, { useState ,useEffect, useReducer, fetchData} from 'react';

function ToDo() {

  const [modalshow,  setModalshow] = useState(false);
  const initModal = () => { return setModalshow(!modalshow) } 
  const [modalText, setModalText] = useState("..");

  const {loading, data, Refresh} =  useBackend('/backend/wwwContent/news');

  return (

        <ContentWrapper>
            <Sida>
                <Rubrik>Example</Rubrik>

                <Alert variant="success" dismissible><Alert.Heading>Testrubrik</Alert.Heading>Test</Alert>

                <Card>
                    <Card.Header><b>Card.Header</b></Card.Header>
                    <Card.Body>
                    <Table responsive="sm">
                      <thead>
                          <tr><th>Col1</th><th>Col2</th><th>Col3</th></tr>
                      </thead>
                      <tbody>
                          <tr><th>Col1.</th><th>Col2</th><th>Col3</th></tr>
                          <tr><th>Col1.</th><th>Col2</th><th>Col3</th></tr>
                      </tbody>
                    </Table>
                    </Card.Body>
                </Card>
                <br></br>

                <Accordion defaultActiveKey="0" >
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Accordion Item #1</Accordion.Header>
                        <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                        culpa qui officia deserunt mollit anim id est laborum.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>                

                <Modal show={modalshow}>
                  <Modal.Header closeButton>
                      <Modal.Title>Modal.Title</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>{modalText}</Modal.Body>
                  <Modal.Footer>
                      <Button variant="danger" onClick={initModal}>Close</Button>
                  </Modal.Footer>
                </Modal>
                <br></br>

                <Button variant='secondary' modaltext="AAA" onClick={(event) => {setModalText(event.target.getAttribute("modaltext") ); setModalshow(!modalshow)} }>Show Modal</Button>
&nbsp;
                <Button variant='secondary' modaltext="BBB" onClick={(event) => {setModalText(event.target.getAttribute("modaltext") ); setModalshow(!modalshow)} }>Show Modal</Button>
                &nbsp;
                <Button variant='secondary' onClick={Refresh }>Refresh</Button>                

            </Sida>
    </ContentWrapper>        
  );
}

export default ToDo;