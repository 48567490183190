import { Container, Form, Alert, Tab, Tabs} from 'react-bootstrap';
import {Sida,Rubrik,Stycke,Button,ContentWrapper} from "../ContentStyles";
import React, { useState ,useEffect} from 'react';
import useBackend, {CallAPI, SpinnerX, CallAPIGet} from '../../services/useBackend';

export default function Login() {
	const [resultText, setResultText] = useState(":)");
	const [resultVariant, setResultVariant] = useState("success");
	const [resultVisible, setResultVisible] = useState(false);
	const [spinnerHidden, setSpinnerHidden] = useState(true);  

	const [size, setSize] = useState({
		x: window.innerWidth,
		y: (window.innerHeight-500)
	});

	const updateSize = () => {
		setSize({
			x: window.innerWidth,
			y: (window.innerHeight-500)
		});
	}
	useEffect(() => (window.onresize = updateSize), []);  
	useEffect(() => (window.onscroll = updateSize), []);

	const delay = ms => new Promise(
		resolve => setTimeout(resolve, ms)
	);  

	async function handleAuthPassword(e) {
		e.preventDefault();
		setSpinnerHidden(false);
		setResultVisible(false);
	
		const formData = new FormData(e.target),
		formDataObj = Object.fromEntries(formData.entries())
		var PostData = {SystemID:"NS", AuthData : formDataObj};
		
		let {resultVariant, resultVisible, resultText, jsondata} = await CallAPI('/api/auth/password','POST',JSON.stringify(PostData));

        if (jsondata.errorsException==false) {
            setResultVariant("success");      
            setResultText(jsondata.resultText);        
            setSpinnerHidden(true);
            setResultVisible(true);
            localStorage.setItem('access_token', jsondata.AccessToken);

			var RedirectTo = "/start";

			if (jsondata.redirectTo!="") RedirectTo = jsondata.StartPage;

            setTimeout(function() {
              window.location.href = RedirectTo;
            }, 1400);

        } else {
            setResultVariant("warning");
            setResultText(jsondata.resultText);
            setSpinnerHidden(true);
            setResultVisible(true);
        }		
	}	

	async function BankIdCollectCallback() {
		console.log(localStorage.getItem('BankIDorderRef'));
		let {jsondata} = await CallAPIGet('/api/auth/bankid/collect/'+localStorage.getItem('BankIDorderRef'));
		console.log(jsondata);
		
	}

	async function handleAuthBankID(e) {
		e.preventDefault();
		setSpinnerHidden(false);
		setResultVisible(false);

		const formData = new FormData(e.target);
		var formDataObj = Object.fromEntries(formData.entries());
		var PostData = {SystemID:"NS", AuthData : formDataObj};
		
		let {jsondata} = CallAPI('/api/auth/bankid/init','POST',JSON.stringify(PostData));
		
        if (jsondata.errorsException==false) {
            setResultVariant("success");      
            setResultText(jsondata.resultText);        
            setSpinnerHidden(true);
            setResultVisible(true);

			console.log(jsondata.orderRef);
			localStorage.setItem('BankIDorderRef', jsondata.orderRef);

			var intervalID = window.setInterval(BankIdCollectCallback, 2500);

        } else {
            setResultVariant("warning");
            setResultText(jsondata.resultText);
            setSpinnerHidden(true);
            setResultVisible(true);
        }		
	}	
	
  	return (
		<ContentWrapper>
			<Sida>
			<Rubrik>Login</Rubrik>

			<Tabs defaultActiveKey="password" id="uncontrolled-tab-example" className="mb-3">


				{/* <Tab eventKey="bankid" title="BankID"> 
					<Form onSubmit={handleAuthBankID}>
						<Form.Group className="mb-3">
							<Form.Label className="FormHeaderText">Personnummer</Form.Label>
							<Form.Control autoFocus type="text" name="ssn" />
						</Form.Group>

						<Form.Group className="mb-3"  hidden={!resultVisible}>
							<Alert show={resultVisible} variant={resultVariant}  className="FormSpace_">{resultText}</Alert> 
						</Form.Group>  

						<Form.Group className="mb-3"  hidden={spinnerHidden}>
							<SpinnerX />
						</Form.Group> 

						<Form.Group className="mb-3">
							<Button className="QmeButton" variant="primary" type="submit">Logga in</Button>
						</Form.Group>                               
					</Form>				
				</Tab> */}

				<Tab eventKey="password" title="Lösenord">
					<Form onSubmit={handleAuthPassword}>
						<Form.Group className="mb-3">
							<Form.Label className="FormHeaderText">Email</Form.Label>
							<Form.Control autoFocus type="text" name="username" />
						</Form.Group>

						<Form.Group className="mb-3">
							<Form.Label className="FormHeaderText">Password</Form.Label>
							<Form.Control type="password" name="password" />
						</Form.Group>

						<Form.Group className="mb-3"  hidden={!resultVisible}>
							<Alert show={resultVisible} variant={resultVariant}  className="FormSpace_">{resultText}</Alert> 
						</Form.Group>  

						<Form.Group className="mb-3"  hidden={spinnerHidden}>
							<SpinnerX  />
						</Form.Group> 

						<Form.Group className="mb-3">
							<Button className="QmeButton" variant="primary" type="submit">Logga in</Button>
						</Form.Group>         

						{/* <Form.Group className="mb-3">
						<a href="/password/request/new" className="text-decoration-none"  style={{color:'#000', fontSize:'16px'}}>Glömt lösenord</a>
						</Form.Group>                        */}
					</Form>
				</Tab>				

			</Tabs> 
			</Sida>
		</ContentWrapper>
  	);
}