import React from "react";
import {Box, Container, Row, Column, FooterLink, Heading, Address, SocialMediaBlock} from "./FooterStyles";

const Footer = () => {
	return (
		<Box>
		<Container>
			<Row>
			<Column>
				<Heading>company</Heading>
				<Address>
							CloudSolution Nordic AB<br />
							SE559213875301
				</Address>
			</Column>
			<Column>
				<Heading>address</Heading>
				<Address>
						Stridsvagnsvägen 14<br />
						SE-291 39 Kristianstad <br />
						Sweden
				</Address>
			</Column>
			<Column>
				<Heading>contact</Heading>
				<Address>
						Email: info@cloudsolution.se <br />
						Phone: +46 (0)702 37 68 86
				</Address>
			</Column>
			</Row>
		</Container>
		</Box>
	);
};

export default Footer;
