import Container from 'react-bootstrap/Container';
import { Alert, Button, Card, Table, Modal, Accordion } from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,Stycke,StyckeCompact} from "../ContentStyles";
import useBackend, {XLogOff, IsLoggedIn, CallAPI, Check} from '../../services/useBackend';
import React, { useState ,useEffect, useReducer, fetchData} from 'react';
import Test from './editRequestComponent';
import {Row, Column} from "../FooterStyles";

function Debug() {

    const [resultVariant, setResultVariant] = useState('warning');
    const [resultText,    setResultText]    = useState('N/A');
    const [resultVisible, setResultVisible] = useState(false);  

    const [editRequestModalshow, setEditRequestModalshow] = useState(false);
    const [selectedRequestData, setSelectedRequestData] = useState({Contact: { Name:"N/A"}, RequestWF: [], RequestChilds : [], RequestLog : [], RequestFiles: [], UserID:0 }); 

    const {loading, data, Refresh} =  useBackend('/api/requests/d66d46c0-211c-410e-9033-c452c4f1c8d6');
    const {loading:loadingmeta, data:metadata} =  useBackend(process.env.PUBLIC_URL+'/api/meta');

    var {loggedIn, isAdmin, isSuperUser, isReportUser, userName } = IsLoggedIn();

    const editRequestHandler = (event, rowData)  =>  {
        setSelectedRequestData(data.Request)
        setEditRequestModalshow(!editRequestModalshow);
    }

    const resetRequestHandler = async (event, rowData)  =>  {
        const formDataObj = {action:'reset'};
        var result = await CallAPI('/api/requests/d66d46c0-211c-410e-9033-c452c4f1c8d6', 'PATCH', JSON.stringify(formDataObj));    

        setResultVisible(true);
        setResultText(result.resultText);

        Refresh();    
    }  

    const longText = "Det här är en lång text i en liten textbox!!";

  return (

        <ContentWrapper>
        {!loading && !loadingmeta &&
            <Test RequestData={selectedRequestData} MetaData={metadata} show={editRequestModalshow} fn={ ()=> { setEditRequestModalshow(!editRequestModalshow); Refresh(); } } setSelectedRequestData={setSelectedRequestData} contactData={selectedRequestData.Contact} />
        }

            <Sida>
                <Rubrik>Debug</Rubrik>

                <Accordion defaultActiveKey="0" >
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>{userName}</Accordion.Header>
                        <Accordion.Body>
                            <Row>                            
                                <Column>loggedIn</Column> <Column><Check checked={loggedIn}/></Column>
                            </Row>
                            <Row>
                                <Column>isAdmin</Column> <Column><Check checked={isAdmin}/></Column>
                            </Row>
                            <Row>
                                <Column>isSuperUser</Column> <Column><Check checked={isSuperUser}/></Column>
                            </Row>
                            <Row>
                                <Column>isReportUser</Column> <Column><Check checked={isReportUser}/></Column>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>  
                <br></br>                

                <Accordion defaultActiveKey="0" >
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Testrequest</Accordion.Header>
                        <Accordion.Body>
                            {!loading && !loadingmeta && <>
                                <button onClick={editRequestHandler}> {data.Request.Title} ({data.Request.Identifier})</button>&nbsp;&nbsp;
                                <button onClick={resetRequestHandler}> Reset {data.Request.Identifier}</button>
                                </>
                            }
                
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>  
                <br></br>

                <Accordion defaultActiveKey="0" >
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Rapporter</Accordion.Header>
                        <Accordion.Body>
                            <a href="/reports/200238f7-02e7-46ad-8874-17de924f4f42">Användare</a><br></br>        
                            <a href="/reports/eccd1139-2e12-48dc-9dd3-2deebc1e7783">Resursgrupper</a><br></br>
                            <a href="/reports/40704f03-d930-4a42-8edb-02b40b4b6871">Resursöversikt</a><br></br>
                            <a href="/reports/32b7109a-9563-450e-afc6-06c98ec84b44">Resursavvikelser</a><br></br>
                            <a href="/reports/8c6aa7a1-f4bf-4871-952d-5c91a420452c">Vakant</a><br></br>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>  
                <br></br>
                <Alert show={resultVisible} variant={resultVariant}  className="FormSpace">{resultText}</Alert>             

            </Sida>
        
    </ContentWrapper>        
  );
}

export default Debug;