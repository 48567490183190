import { Container, Button, Navbar, Nav, NavItem, NavDropdown, MenuItem, NavLink,FormControl ,Form, Alert} from 'react-bootstrap';
import React, { useState, Component } from 'react';
import {Sida,Rubrik,Stycke,ContentWrapper} from "./ContentStyles";



const shoot = () => {
  alert();
}

const Home = () => {

   return(
        <ContentWrapper>
        <Sida>
          <Alert variant='danger'>
            <Alert.Heading>Vad händer?</Alert.Heading>
            • Businessobject (CS) är uppgraderat till en modern plattform (.Net Core 9)<br></br>
            • Detta skapar förutsättningar för nya funktioner och bättre prestanda.<br></br>
          </Alert> 

          <Alert>
            <Alert.Heading>Tips</Alert.Heading>
            Om du upplever problem med en funktion eller inte fått tillgång till de senaste funktionerna tryck CTRL+F5 i din webbläsare. Detta säkerställer att du har den senaste koden i cachen/minnet.
          </Alert> 

          <Alert variant='info'>
            <Alert.Heading>Att göra</Alert.Heading>
            Det som är på gång finns samlat i Request 21484.
          </Alert>   

          <Alert variant='danger'>
            <Alert.Heading>Lösenord</Alert.Heading>
            Inom kort kommer det ställas högre krav på lösenord. Det innebär bland annat:<br></br>
            • Minst 10 tecken i lösenordet<br></br>
            • Komplexitet i form av både siffror, bokstäver och specialtecken.<br></br>
            • Medarbetare som har lösenord som inte lever upp till kraven kommer innom kort bli uppmanade att byta lösenord.<br></br>
            • Lösenord kommer skilja på versaler och gemener.<br></br>
            • Medarbetare kan redan nu via användarmenyn i övre högra hörnet byta lösenord.
          </Alert>   

          <Alert>
            <Alert.Heading>Ny funktionalitet omfattning 1</Alert.Heading>
              •	Implementera två nya tids-fält för taskarna, ett för beräknad/uppskattad tid och ett för vad som gick åt i verkligheten (uppföljning). <p style={{color:"red", display:"inline", fontSize:"16px"}} >(Levererat)</p><br></br>
              •	Möjlighet att gruppera personalresurser (resursgrupper), tex Laser, Verkstad, Svets, Kantbock. Detta för att lättare kunna gruppera resursåtgången i rapporter. <p style={{color:"red", display:"inline", fontSize:"16px"}} >(Levererat)</p><br></br>
              •	Skapa rapport för att kunna se tidsåtgången per resursgrupp (Laser, Verkstad, Svets, Kantbock) / dag eller vecka. Rapporten kommer då att aggregera och gruppera tiden för alla taskar på en specifik resursgrupp för den dag man väljer att visa. Tex att kantbockarna på tisdag har 27 timmars planerat arbete (om de då är två personer som jobbar 8h/dag så behöver man planera om eller jobba över).  <p style={{color:"red", display:"inline", fontSize:"16px"}} >(Klart för leverans 2025-02-28)</p><br></br>
          </Alert>

          <Alert>
            <Alert.Heading>Ny funktionalitet omfattning 2</Alert.Heading>
             •	Kunna kopiera en befintlig avslutad request till en helt ny men nytt id och nya datum <p style={{color:"red", display:"inline", fontSize:"16px"}} >(Levererat)</p>
          </Alert>

          <Alert>
          <Alert.Heading>Ny funktionalitet omfattning 3</Alert.Heading>
            •	Ändra till B&H färger <p style={{color:"red", display:"inline", fontSize:"16px"}} >(Levererat 2025-03-17)</p><br></br>
            •	Implementera snabbavslut för Task <p style={{color:"red", display:"inline", fontSize:"16px"}} >(Levererat 2025-02-20)</p><br></br>
            •	Kunna ändra datum för task direkt i översikten <p style={{color:"red", display:"inline", fontSize:"16px"}} >(Levererat 2025-02-20)</p>
          </Alert>                          

        </Sida>    

        </ContentWrapper>
      );
   }

export default Home;